import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    storePartner(data) {
        return axios.post(`${ENDPOINT_PATH}/partner`, data);
    },
    getPartners(params = null) {
        return axios.get(`${ENDPOINT_PATH}/partners`, { params });
    },
    getPartnerById(id) {
        return axios.get(`${ENDPOINT_PATH}/partner/${id}`)
    },
    editPartner(partner) {
        return axios.put(`${ENDPOINT_PATH}/partner/${partner.id}`, partner)
    }
};
