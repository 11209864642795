export const FETCH_REPORT_PARTNER_ACTIVE_REQUEST = 'FETCH_REPORT_PARTNER_ACTIVE_REQUEST'
export const FETCH_REPORT_PARTNER_ACTIVE_SUCCESS = 'FETCH_REPORT_PARTNER_ACTIVE_SUCCESS'
export const FETCH_REPORT_PARTNER_ACTIVE_FAILURE = 'FETCH_REPORT_PARTNER_ACTIVE_FAILURE'

export const FETCH_REPORT_PARTNER_NEW_REQUEST = 'FETCH_REPORT_PARTNER_NEW_REQUEST'
export const FETCH_REPORT_PARTNER_NEW_SUCCESS = 'FETCH_REPORT_PARTNER_NEW_SUCCESS'
export const FETCH_REPORT_PARTNER_NEW_FAILURE = 'FETCH_REPORT_PARTNER_NEW_FAILURE'

export const FETCH_REPORT_GUESTS_REQUEST = 'FETCH_REPORT_GUESTS_REQUEST'
export const FETCH_REPORT_GUESTS_SUCCESS = 'FETCH_REPORT_GUESTS_SUCCESS'
export const FETCH_REPORT_GUESTS_FAILURE = 'FETCH_REPORT_GUESTS_FAILURE'


export const EXPORT_REPORT_PARTNER_ACTIVE = 'EXPORT_REPORT_PARTNER_ACTIVE'
export const EXPORT_REPORT_PARTNER_NEW = 'EXPORT_REPORT_PARTNER_NEW'
export const EXPORT_REPORT_GUESTS = 'EXPORT_REPORT_GUESTS'