import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    getPartnerActive(params = null) {
        return axios.get(`${ENDPOINT_PATH}/reports/partner-active`, { params });
    },
    getPartnerNew(params = null) {
        return axios.get(`${ENDPOINT_PATH}/reports/partner-new`, { params });
    },
    getGuests(params = null) {
        return axios.get(`${ENDPOINT_PATH}/reports/guests`, { params })
    },
    exportPartnerActive(params = null) {
        return axios.get(`${ENDPOINT_PATH}/reports/partners-active/export`, {
            params, responseType: "arraybuffer"
        })
    },
    exportPartnerNew(params = null) {
        return axios.get(`${ENDPOINT_PATH}/reports/partners-new/export`, {
            params, responseType: "arraybuffer"
        })
    },
    exportGuests(params = null) {
        return axios.get(`${ENDPOINT_PATH}/reports/guests/export`, {
            params, responseType: "arraybuffer"
        })
    }
};
