import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'
import globalState from './states/global'
import modules from './modules'



Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

/// lo contruyo con modules import 
// const modulesDefault = {
//   auth: AuthModule,
//   util: UtilModule,
//   guest: GuestModule,
//   reception: ReceptionModule,
//   payment: PaymentModule,
//   membership: MembershipModule,
//   partner: PartnerModule,
//   associated: AssociatedModule,
//   user: UserModule
// }

// console.log(modulesDefault)


export default new Vuex.Store({
  strict: debug,
  plugins: debug ? [createLogger()] : [],
  state: {
    ...globalState,
  },
  mutations: {
  },
  actions: {
  },
  getters: {
  },
  modules
  // modules: {
  //   ...modules
  //   auth: AuthModule,
  //   util: UtilModule,
  //   guest: GuestModule,
  //   reception: ReceptionModule,
  //   payment: PaymentModule,
  //   membership: MembershipModule,
  //   partner: PartnerModule,
  //   associated: AssociatedModule,
  //   user: UserModule
  //   ...modulesDefault
  // }
})