const DefaultRoute = {
    'admin': '/',
    'recepcionista': '/recepcion'
}


DefaultRoute.install = function (Vue) {
    Vue.prototype.$getConst = (key) => {
        return DefaultRoute[key];
    }
};

export default DefaultRoute;