import * as types from '../types/auth'
import APIauth from '@/services/auth'
import axios from "axios";



const defaultState = {
    status: '',
    token: localStorage.getItem('token') || '',
    user: {}
}


const mutations = {
    [types.INITIALIZE_STORE](state) {
        let user = localStorage.getItem('user');
        if (user) state.user = JSON.parse(user);
    },
    [types.AUTH_REQUEST](state) {
        state.status = 'loading'
    },
    [types.AUTH_REQUEST_SUCCESS](state, { token, user }) {
        state.status = 'sucess'
        state.token = token
        localStorage.setItem('user', JSON.stringify(user))
        state.user = user
    },
    [types.AUTH_REQUEST_FAILURE](state, { error }) {
        state.status = 'error'
    },
    [types.LOGOUT](state) {
        localStorage.removeItem('token')
        state.status = ''
        state.token = ''
    },
    [types.SET_ITEM_TOKEN](state, { token }) {
        localStorage.setItem('token', token)
    },
    [types.SET_DEFAULT_HEADER](state, { token }) {
        axios.defaults.headers.common['Authorization'] = token
    },
    [types.REMOVE_TOKEN](state){
        localStorage.removeItem('token')
    }
}

const actions = {
    login({ commit }, { user }) {
        return new Promise((resolve, reject) => {
            commit(types.AUTH_REQUEST)
            APIauth.login(user)
            .then((response) => {
                commit(types.SET_ITEM_TOKEN, { ...response.data })
                commit(types.SET_DEFAULT_HEADER, { ...response.data })
                commit(types.AUTH_REQUEST_SUCCESS, { ...response.data })
                resolve(response)
            })
            .catch((err) => {
                commit(types.AUTH_REQUEST_FAILURE, { error: err.response.data })
                commit(types.REMOVE_TOKEN)
                reject(err.response.data )
            })
        })
    },
    logout ({commit}){
        commit(types.LOGOUT)
    }
}

const getters = {
    isLoggedIn:(state) =>()=>{
        return  !!state.token
    },
    authStatus:(state) => ()=> {
        return state.status
    },
    userSession: state => state.user
}


export default {
    state: defaultState,
    getters,
    actions,
    mutations,
};
