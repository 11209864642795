import APIutil from '@/services/util'
import * as types from '../types/util'


const defaultState = {
    documents: [],
    locations: [],
    relationships: [],
    title: '',
    photo_url: null,
    payment_methods: [],
    departments: [],
    provinces: [],
    districts: [],
    fileAdd: {},
    fileDelete: {},
    errorMaxFotos: null,
    partner_types : []

}


const mutations = {

    [types.FETCH_DOCUMENTS_REQUEST](state) {
        state.fetchingData = true
        state.error = null
    },
    [types.FETCH_DOCUMENTS_SUCCESS](state, { documents }) {
        state.fetchingData = false
        state.error = null
        state.documents = documents
    },
    [types.FETCH_DOCUMENTS_FAILURE](state, { error }) {
        state.fetchingData = false
        state.error = error
    },
    [types.FETCH_LOCATIONS_REQUEST](state) {
        state.fetchingData = true
        state.error = null
    },
    [types.FETCH_LOCATIONS_SUCCESS](state, { locations }) {
        state.fetchingData = false
        state.error = null
        state.locations = locations
    },
    [types.FETCH_LOCATIONS_FAILURE](state, { error }) {
        state.fetchingData = false
        state.error = error
    },
    [types.FETCH_RELATIONSHIPS_REQUEST](state) {
        state.fetchingData = true
        state.error = null
    },
    [types.FETCH_RELATIONSHIPS_SUCCESS](state, { relationships }) {
        state.fetchingData = false
        state.error = null
        state.relationships = relationships
        //edit partner commit
    },
    [types.FETCH_RELATIONSHIPS_FAILURE](state, { error }) {
        state.fetchingData = false
        state.error = error
    },
    [types.UPLOAD_IMAGE_REQUEST](state) {
        state.fetchingData = true
        state.error = null
        state.photo_url = null
    },
    [types.UPLOAD_IMAGE_SUCCES](state, { file_url }) {
        state.fetchingData = false
        state.error = null
        state.photo_url = file_url
    },
    [types.UPLOAD_IMAGE_FAILURE](state, { error }) {
        state.fetchingData = false
        state.error = error
    },
    [types.UPLOAD_FILE_REQUEST](state) {
        state.fetchingData = true
        state.error = null
        state.fileAdd = {}
        state.errorMaxFotos = null
    },
    [types.UPLOAD_FILE_SUCCESS](state, { file_url, file, rootState, module }) {
        state.fetchingData = false
        let tmp = Object.assign({}, { file_url, name: file.originalname, operation: "add", createdAt: new Date() });
        if(rootState[module][module].files){
            if (rootState[module][module].files.filter(e=> e.operation!='delete').length < 5) {
                rootState[module][module].files.push(tmp)
                state.fileAdd = tmp;
            } else {
                state.errorMaxFotos = 'No puede tener mas de 5 archivos.'
            }
        }else{
            rootState[module][module]={
                files: [tmp]
            }
            state.fileAdd = tmp;
        }
    },
    [types.UPLOAD_FILE_FAILURE](state, { error }) {
        state.fetchingData = false
        state.error = error
        state.errorMaxFotos = null;
    },
    [types.SET_FILE_DELETE](state, { rootState, file, module }) {
        state.fetchingData = false
        state.error = null
        if (file.id) {
            rootState[module][module].files.forEach(e => {
                if (e.id == file.id) {
                    e.operation = 'delete'
                }
            })
        } else {
            rootState[module][module].files.splice(rootState[module][module].files.findIndex((e) => e.name == file.name), 1)
        }
        state.fileDelete = file
    },
    [types.FETCH_PAYMENT_METHODS_REQUEST](state) {
        state.fetchingData = true
        state.error = null
    },
    [types.FETCH_PAYMENT_METHODS_SUCCESS](state, { payment_methods }) {
        state.fetchingData = false
        state.error = null
        state.payment_methods = payment_methods
    },
    [types.FETCH_PAYMENT_METHODS_FAILURE](state, { error }) {
        state.fetchingData = false
        state.error = error
    },
    [types.FETCH_DEPARTMENTS_REQUEST](state) {
        state.fetchingData = true
        state.error = null
    },
    [types.FETCH_DEPARTMENTS_SUCCESS](state, { departments }) {
        state.fetchingData = false
        state.error = null
        state.departments = departments
    },
    [types.FETCH_DEPARTMENTS_FAILURE](state, { error }) {
        state.fetchingData = false
        state.error = error
    },
    [types.FETCH_PROVINCES_REQUEST](state) {
        state.fetchingData = true
        state.error = null
    },
    [types.FETCH_PROVINCES_SUCCESS](state, { provinces }) {
        state.fetchingData = false
        state.error = null
        state.provinces = provinces
    },
    [types.FETCH_PROVINCES_FAILURE](state, { error }) {
        state.fetchingData = false
        state.error = error
    },
    [types.FETCH_DISTRICTS_REQUEST](state) {
        state.fetchingData = true
        state.error = null
    },
    [types.FETCH_DISTRICTS_SUCCESS](state, { districts }) {
        state.fetchingData = false
        state.error = null
        state.districts = districts
    },
    [types.FETCH_DISTRICTS_FAILURE](state, { error }) {
        state.fetchingData = false
        state.error = error
    },

    [types.FETCH_PARTNER_TYPES_REQUEST](state) {
        state.fetchingData = true
        state.error = null
    },
    [types.FETCH_PARTNER_TYPES_SUCCESS](state, { partner_types }) {
        state.fetchingData = false
        state.error = null
        state.partner_types = partner_types
    },
    [types.FETCH_PARTNER_TYPES_FAILURE](state, { error }) {
        state.fetchingData = false
        state.error = error
    },
}

const actions = {
    fetchDocuments({ commit }) {
        commit(types.FETCH_DOCUMENTS_REQUEST)
        APIutil.getDocuments()
            .then((response) => commit(types.FETCH_DOCUMENTS_SUCCESS, { ...response.data }))
            .catch((error) => commit(types.FETCH_LOCATIONS_FAILURE, { error: error.response.data }))
    },
    fetchLocations({ commit }) {
        commit(types.FETCH_LOCATIONS_REQUEST)
        APIutil.getLocations()
            .then((response) => commit(types.FETCH_LOCATIONS_SUCCESS, { ...response.data }))
            .catch((error) => commit(types.FETCH_LOCATIONS_FAILURE, { error: error.response.data }))
    },
    fetchRelationships({ commit }) {
        commit(types.FETCH_RELATIONSHIPS_REQUEST)
        APIutil.getRelationships()
            .then((response) => commit(types.FETCH_RELATIONSHIPS_SUCCESS, { ...response.data }))
            .catch((error) => commit(types.FETCH_RELATIONSHIPS_FAILURE, { error: error.response.data }))
    },
    uploadImage({ commit }, { data }) {
        commit(types.UPLOAD_IMAGE_REQUEST)
        APIutil.uploadImage(data)
            .then((response) => commit(types.UPLOAD_IMAGE_SUCCES, { ...response.data }))
            .catch((error) => commit(types.UPLOAD_IMAGE_FAILURE, { error: error.response.data }))
    },
    uploadFile({ commit, rootState }, { data, module }) {
        commit(types.UPLOAD_FILE_REQUEST)
        APIutil.uploadFile(data)
            .then((response) => commit(types.UPLOAD_FILE_SUCCESS, { ...response.data, rootState, module }))
            .catch((error) => commit(types.UPLOAD_FILE_FAILURE, { error: error.response.data }))
    },
    fetchPaymentMethods({ commit }) {
        commit(types.FETCH_PAYMENT_METHODS_REQUEST)
        APIutil.getPaymentMethods()
            .then((response) => commit(types.FETCH_PAYMENT_METHODS_SUCCESS, { ...response.data }))
            .catch((error) => commit(types.FETCH_PAYMENT_METHODS_FAILURE, { error: error.response.data }))
    },
    fetchDepartments({ commit }) {
        commit(types.FETCH_DEPARTMENTS_REQUEST)
        APIutil.getDepartments()
            .then((response) => commit(types.FETCH_DEPARTMENTS_SUCCESS, { ...response.data }))
            .catch((error) => commit(types.FETCH_DEPARTMENTS_FAILURE, { error: error.response.data }))
    },
    fetchProvinces({ commit }, { department_id }) {
        commit(types.FETCH_PROVINCES_REQUEST)
        APIutil.getProvinces(department_id)
            .then((response) => commit(types.FETCH_PROVINCES_SUCCESS, { ...response.data }))
            .catch((error) => commit(types.FETCH_PROVINCES_FAILURE, { error: error.response.data }))
    },
    fetchDistricts({ commit }, { province_id }) {
        commit(types.FETCH_DISTRICTS_REQUEST)
        APIutil.getDistricts(province_id)
            .then((response) => commit(types.FETCH_DISTRICTS_SUCCESS, { ...response.data }))
            .catch((error) => commit(types.FETCH_DISTRICTS_FAILURE, { error: error.response.data }))
    },
    deleteFile({ commit, rootState }, { file, module }) {
        commit(types.SET_FILE_DELETE, { rootState, file, module })
    },
    fetchPartnerTypes({ commit }) {
        commit(types.FETCH_PARTNER_TYPES_REQUEST)
        APIutil.getParnerTypes()
            .then((response) => commit(types.FETCH_PARTNER_TYPES_SUCCESS, { ...response.data }))
            .catch((error) => commit(types.FETCH_PARTNER_TYPES_FAILURE, { error: error.response.data }))
    },
}



export default {
    state: defaultState,
    // getters,
    actions,
    mutations,
};

