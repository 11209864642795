import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import store from '@/store/index'
import DefaultRoute from '@/helpers/default-routes.js';
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    // component: Home,
    component: () => import('@/views/Home.vue'),
    meta: {
      requiresAuth: true,
      title: 'Dashboard',
      roles: ['admin']
    },
    children : [
      {
        path: '',
        name: 'dashboard',
        // component: Home,
        component: () => import('@/views/dashboard/Index.vue'),
        meta: {
          requiresAuth: true,
          title: 'Dashboard',
          id: 'home',
          roles: ['admin']
        }
      },
      {
        path: 'socios-add',
        name: 'socios-add',
        component: () => import('@/views/partner/PartnerAddEdit.vue'),
        meta: {
          title: 'Socio',
          requiresAuth: true,
          id: 'socio',
          roles: ['admin']
        }
      },
      {
        path: '/socios',
        name: 'socios',
        props: true,
        component: () => import('@/views/partner/Index.vue'),
        meta: {
          title: 'Socios',
          requiresAuth: true,
          id: 'socio',
          roles: ['admin']
        },
        children : [
          {
            path: '',
            name: 'socio-index',
            props: true,
            component: () => import('@/views/partner/Partners.vue'),
            meta: {
              title: 'Socios',
              requiresAuth: true,
              id: 'socio',
              roles: ['admin']
            },
          },
          {
            path: ':id',
            name: 'socios-detalle',
            props: true,
            component: () => import('@/views/partner/PartnerDetail.vue'),
            meta: {
              title: 'Socios',
              requiresAuth: true,
              id: 'socio',
              roles: ['admin']
            }
          },
          {
            path: 'edit/:id',
            name: 'socios-edit',
            props: true,
            component: () => import('@/views/partner/PartnerAddEdit.vue'),
            meta: {
              title: 'Socio',
              requiresAuth: true,
              id: 'socio',
              roles: ['admin']
            }
          },
          {
            path: 'asociado-add/:membership_id',
            name: 'asociado-add',
            props: true,
            component: () => import('@/views/associated/AssociatedAddEdit.vue'),
            meta: {
              title: 'Socio',
              requiresAuth: true,
              id: 'socio',
              roles: ['admin']
            }
          },
          {
            path: 'asociado-edit/:id',
            name: 'asociado-edit',
            props: true,
            component: () => import('@/views/associated/AssociatedAddEdit.vue'),
            meta: {
              title: 'Socio',
              requiresAuth: true,
              id: 'socio',
              roles: ['admin']
            }
          },
        ]
      },
    
      {
        path: '/invitados',
        name: 'invitados',
        props: true,
        component: () => import('@/views/guest/Index.vue'),
        meta: {
          title: 'Invitados',
          requiresAuth: true,
          id:'invitados',
          roles: ['admin']
        },
      },
      {
        path: '/membresias',
        name: 'membresias',
        props: true,
        component: () => import('@/views/membership/Index.vue'),
        meta: {
          title: 'Membresías',
          requiresAuth: true,
          id: 'membresia',
          roles: ['admin']
        },
        children: [
          {
    
            path: '',
            props: true,
            name: 'membresias-index',
            component: () => import('@/views/membership/Memberships.vue'),
            meta: {
              title: 'Membresías',
              requiresAuth: true,
              id: 'membresia',
              roles: ['admin']
            },
    
          },
          {
            path: ':id',
            name: 'membresia',
            props: true,
            component: () => import('@/views/membership/MembershipDetail.vue'),
            meta: {
              title: 'Membresías',
              requiresAuth: true,
              id: 'membresia',
              roles: ['admin']
            },
            children :[
              {
                path: 'pagos',
                name: 'membresia-pagos',
                props: true,
                component: () => import('@/views/membership/Payments.vue'),
                meta: {
                  title: 'Membresías',
                  requiresAuth: true,
                  id: 'membresia',
                  roles: ['admin']
                },
                
              },
              {
                path: 'pago/:paymentId',
                props: true,
                name: 'membresia-pago-detail',
                component: () => import('@/views/membership/Payment.vue'),
                meta: {
                  title: 'Membresías',
                  requiresAuth: true,
                  id: 'membresia',
                  roles: ['admin']
                },
              }
            ]
          },
          {
            path :'pagos/edit/:id',
            props:true,
            name :'membresias-pagos-edit',
            component: () => import('@/views/membership/PaymentEdit.vue'),
            meta: {
              title: 'Membresías',
              requiresAuth: true,
              id: 'membresia',
              roles: ['admin']
            },
    
          }
        ]
      },
     
      {
        path: '/recepcion',
        name: 'recepcion',
        component: () => import('@/views/reception/Index.vue'),
        meta: {
          title: 'Recepción',
          requiresAuth: true,
          id:'recepcion',
          roles: ['admin','recepcionista']
        }
      },
      {
        path: '/reportes',
        name: 'reportes',
        component: () => import('@/views/report/Index.vue'),
        meta: {
          title: 'Reportes',
          requiresAuth: true,
          id:'reportes',
          roles: ['admin']
        },
        children: [
          {
            path: 'socios-activos',
            name: 'reporte-socios-activos',
            component: () => import('@/components/tables/TableReportPartnerActive.vue'),
            meta: {
              title: 'Reportes',
              requiresAuth: true,
              id:'reportes',
              roles: ['admin']
            },
          },
          {
            path: 'socios-nuevos',
            name: 'reporte-socios-nuevos',
            component: () => import('@/components/tables/TableReportPartnerNew.vue'),
            meta: {
              title: 'Reportes',
              requiresAuth: true,
              id:'reportes',
              roles: ['admin']
            },
          },
          {
            path: 'invitados',
            name: 'reporte-invitados',
            component: () => import('@/components/tables/TableReportGuest.vue'),
            meta: {
              title: 'Reportes',
              requiresAuth: true,
              id:'reportes',
              roles: ['admin']
            },
          }
        ]
      },
      {
        path: '/usuarios',
        name: 'usuarios',
        component: () => import('@/views/user/Index.vue'),
        meta: {
          title: 'Usuarios',
          requiresAuth: true,
          id:'usuarios',
          roles: ['admin']
        },
        children: [
          {
    
            path: '',
            props: true,
            name: 'users-index',
            component: () => import('@/views/user/Users.vue'),
            meta: {
              title: 'Usuarios',
              requiresAuth: true,
              id: 'usuarios',
              roles: ['admin']
            },
    
          },
          {
    
            path: 'add',
            props: true,
            name: 'user-add',
            component: () => import('@/views/user/UserAddEdit.vue'),
            meta: {
              title: 'Usuarios',
              requiresAuth: true,
              id: 'usuarios',
              roles: ['admin']
            },
    
          },
          {
    
            path: 'edit/:id',
            props: true,
            name: 'user-edit',
            component: () => import('@/views/user/UserAddEdit.vue'),
            meta: {
              title: 'Usuarios',
              requiresAuth: true,
              id: 'usuarios',
              roles: ['admin']
            },
    
          },
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      title: 'Login',
    }
  },
  {
    path: '/privacy-protection-policies',
    name: 'privacy',
    component: () => import('@/views/PrivacyPolicies.vue'),
    meta: {
      title: 'Privacy Policies',
    }
  },
  { path: '*', component: () => import('@/components/layout/404.vue') }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "active"
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn()) {
      console.log(store.getters.isLoggedIn())
      // return next()
      
      const {roles}=to.meta;
      if(roles.includes(store.getters.userSession.role)){
        return next()
      }else{
        return next( { path: DefaultRoute[store.getters.userSession.role] })
      }
    }
    next('/login')
  } else {
    next()
  }
})

export default router
