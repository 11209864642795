import * as types from '../types/guest'
import APIguest from '@/services/guest'

const defaultState = {
    guests: [],
}

const mutations = {
    [types.FETCH_GUESTS_REQUEST](state) {
        state.fetchingData = true
        state.error = null
    },
    [types.FETCH_GUESTS_SUCCESS](state, { guests }) {
        state.fetchingData = false
        state.error = null
        state.guests = guests
    },
    [types.FETCH_GUESTS_FAILURE](state, { error }) {
        state.fetchingData = false
        state.error = error
    },
}

const actions= {
    fetchGuests({ commit }, { params }) {
        commit(types.FETCH_GUESTS_REQUEST)
        APIguest.getGuests(params)
            .then((response) => commit(types.FETCH_GUESTS_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.FETCH_GUESTS_FAILURE, { error: err.response.data }))
    },
}



export default {
    state: defaultState,
    // getters,
    actions,
    mutations,
};