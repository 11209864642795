export const FETCH_DOCUMENTS_REQUEST = 'FETCH_DOCUMENTS_REQUEST'
export const FETCH_DOCUMENTS_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS'
export const FETCH_DOCUMENTS_FAILURE = 'FETCH_DOCUMENTS_FAILURE'

export const FETCH_LOCATIONS_REQUEST = 'FETCH_LOCATIONS_REQUEST'
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS'
export const FETCH_LOCATIONS_FAILURE = 'FETCH_LOCATIONS_FAILURE'

export const FETCH_RELATIONSHIPS_REQUEST = 'FETCH_RELATIONSHIPS_REQUEST'
export const FETCH_RELATIONSHIPS_SUCCESS = 'FETCH_RELATIONSHIPS_SUCCESS'
export const FETCH_RELATIONSHIPS_FAILURE = 'FETCH_RELATIONSHIPS_FAILURE'

export const UPLOAD_IMAGE_REQUEST = 'UPLOAD_IMAGE_REQUEST'
export const UPLOAD_IMAGE_SUCCES = 'UPLOAD_IMAGE_SUCCES'
export const UPLOAD_IMAGE_FAILURE = 'UPLOAD_IMAGE_FAILURE'

export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST'
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS'
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE'

export const SET_FILE_DELETE = 'SET_FILE_DELETE'

export const FETCH_PAYMENT_METHODS_REQUEST = 'FETCH_PAYMENT_METHODS_REQUEST'
export const FETCH_PAYMENT_METHODS_SUCCESS = 'FETCH_PAYMENT_METHODS_SUCCESS'
export const FETCH_PAYMENT_METHODS_FAILURE = 'FETCH_PAYMENT_METHODS_FAILURE'


export const FETCH_DEPARTMENTS_REQUEST = 'FETCH_DEPARTMENTS_REQUEST'
export const FETCH_DEPARTMENTS_SUCCESS = 'FETCH_DEPARTMENTS_SUCCESS'
export const FETCH_DEPARTMENTS_FAILURE = 'FETCH_DEPARTMENTS_FAILURE'

export const FETCH_PROVINCES_REQUEST = 'FETCH_PROVINCES_REQUEST'
export const FETCH_PROVINCES_SUCCESS = 'FETCH_PROVINCES_SUCCESS'
export const FETCH_PROVINCES_FAILURE = 'FETCH_PROVINCES_FAILURE'

export const FETCH_DISTRICTS_REQUEST = 'FETCH_DISTRICTS_REQUEST'
export const FETCH_DISTRICTS_SUCCESS = 'FETCH_DISTRICTS_SUCCESS'
export const FETCH_DISTRICTS_FAILURE = 'FETCH_DISTRICTS_FAILURE'


export const FETCH_PARTNER_TYPES_REQUEST = 'FETCH_PARTNER_TYPES_REQUEST'
export const FETCH_PARTNER_TYPES_SUCCESS = 'FETCH_PARTNER_TYPES_SUCCESS'
export const FETCH_PARTNER_TYPES_FAILURE = 'FETCH_PARTNER_TYPES_FAILURE'