import * as types from '../types/report'
import APIreport from '@/services/report'
import { saveAs } from 'file-saver';

const defaultState = {
    report_partner_actives: [],
    report_partner_news: [],
    report_guests: [],
    reportError: null,
}

const mutations = {
    [types.FETCH_REPORT_PARTNER_ACTIVE_REQUEST](state) {
        state.fetchingData = true
        state.reportError = null
    },
    [types.FETCH_REPORT_PARTNER_ACTIVE_SUCCESS](state, { report_partner_actives }) {
        state.fetchingData = false
        state.reportError = null
        state.report_partner_actives = report_partner_actives
    },
    [types.FETCH_REPORT_PARTNER_ACTIVE_FAILURE](state, { error }) {
        state.fetchingData = false
        state.reportError = error
    },
    [types.FETCH_REPORT_PARTNER_NEW_REQUEST](state) {
        state.fetchingData = true
        state.reportError = null
    },
    [types.FETCH_REPORT_PARTNER_NEW_SUCCESS](state, { report_partner_news }) {
        state.fetchingData = false
        state.reportError = null
        state.report_partner_news = report_partner_news
    },
    [types.FETCH_REPORT_PARTNER_NEW_FAILURE](state, { error }) {
        state.fetchingData = false
        state.reportError = error
    },
    [types.FETCH_REPORT_GUESTS_REQUEST](state) {
        state.fetchingData = true
        state.reportError = null
    },
    [types.FETCH_REPORT_GUESTS_SUCCESS](state, { report_guests }) {
        state.fetchingData = false
        state.reportError = null
        state.report_guests = report_guests
    },
    [types.FETCH_REPORT_GUESTS_FAILURE](state, { error }) {
        state.fetchingData = false
        state.reportError = error
    },
}

const actions = {
    getPartnerActive({ commit }, { params }) {
        commit(types.FETCH_REPORT_PARTNER_ACTIVE_REQUEST)
        APIreport.getPartnerActive(params)
            .then((response) => commit(types.FETCH_REPORT_PARTNER_ACTIVE_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.FETCH_REPORT_PARTNER_ACTIVE_FAILURE, { error: err.response.data }))
    },
    getPartnerNew({ commit }, { params }) {
        commit(types.FETCH_REPORT_PARTNER_NEW_REQUEST)
        APIreport.getPartnerNew(params)
            .then((response) => commit(types.FETCH_REPORT_PARTNER_NEW_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.FETCH_REPORT_PARTNER_NEW_FAILURE, { error: err.response.data }))
    },
    getGuests({ commit }, { params }) {
        commit(types.FETCH_REPORT_GUESTS_REQUEST)
        APIreport.getGuests(params)
            .then((response) => commit(types.FETCH_REPORT_GUESTS_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.FETCH_REPORT_GUESTS_FAILURE, { error: err.response.data }))
    },
    exportPartnerActive({ commit }, { params }) {
        APIreport.exportPartnerActive(params)
            .then((response) => {
                const data = new Blob([response.data], { type: response.headers['content-type'] });
                const filename = response.headers['content-disposition'].split('filename=')[1].split(';')[0];
                saveAs(data, filename);
            }).catch((err) => {
                console.log(err);
            })
    },
    exportPartnerNew({ commit }, { params }) {
        APIreport.exportPartnerNew(params)
            .then((response) => {
                const data = new Blob([response.data], { type: response.headers['content-type'] });
                const filename = response.headers['content-disposition'].split('filename=')[1].split(';')[0];
                saveAs(data, filename);
            }).catch((err) => {
                console.log(err);
            })
    },
    exportGuests({ commit }, { params }) {
        APIreport.exportGuests(params)
            .then((response) => {
                const data = new Blob([response.data], { type: response.headers['content-type'] });
                const filename = response.headers['content-disposition'].split('filename=')[1].split(';')[0];
                saveAs(data, filename);
            }).catch((err) => {
                console.log(err);
            })
    },
}



export default {
    state: defaultState,
    // getters,
    actions,
    mutations,
};

