import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    storeUser(data) {
        return axios.post(`${ENDPOINT_PATH}/web-user`, data);
    },
    getUsers(params = null) {
        return axios.get(`${ENDPOINT_PATH}/web-users`, { params });
    },
    getUserById(id) {
        return axios.get(`${ENDPOINT_PATH}/web-user/${id}`)
    },
    editUser(user) {
        return axios.put(`${ENDPOINT_PATH}/web-user/${user.id}`, user)
    }
};
