import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    getReceptions(params = null) {
        return axios.get(`${ENDPOINT_PATH}/receptions`, { params });
    },
    addReceptionByUserId (id){
        return axios.post(`${ENDPOINT_PATH}/reception/${id}`);
    },
    addReceptionByDocumentNumber (document_number){
        return axios.post(`${ENDPOINT_PATH}/reception-dni/${document_number}`);
    }
};
