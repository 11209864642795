import * as types from '../types/membership'
import APImembership from '@/services/membership'



const defaultState = {
    memberships: [],
    membership: {}
}

const mutations = {

    [types.FETCH_MEMBERSHIPS_REQUEST](state) {
        state.fetchingData = true
        state.error = null
    },
    [types.FETCH_MEMBERSHIPS_SUCCESS](state, { memberships }) {
        state.fetchingData = false
        state.error = null
        state.memberships = memberships
    },
    [types.FETCH_MEMBERSHIPS_FAILURE](state, { error }) {
        state.fetchingData = false
        state.error = error
    },
    [types.GET_MEMBERSHIP_BY_ID_REQUEST](state) {
        state.fetchingData = true
        state.error = null
    },
    [types.GET_MEMBERSHIP_BY_ID_SUCCESS](state, { membership }) {
        state.fetchingData = false
        state.error = null
        state.membership = membership
    },
    [types.FETCH_MEMBERSHIPS_FAILURE](state, { error }) {
        state.fetchingData = false
        state.error = error
    },

}


const actions = {

    fetchMemberships({ commit }, { params }) {
        commit(types.FETCH_MEMBERSHIPS_REQUEST)
        APImembership.getMemberships(params)
            .then((response) => commit(types.FETCH_MEMBERSHIPS_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.FETCH_MEMBERSHIPS_FAILURE, { error: err.response.data }))
    },
    getMembershipById({ commit }, { id }) {
        commit(types.GET_MEMBERSHIP_BY_ID_REQUEST)
        APImembership.getMembershipById(id)
            .then((response) => commit(types.GET_MEMBERSHIP_BY_ID_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.GET_MEMBERSHIP_BY_ID_FAILURE, { error: err.response.data }))
    },

}


export default {
    state: defaultState,
    // getters,
    actions,
    mutations,
};
