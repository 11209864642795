import * as types from '../types/payment'
import APIpayment from '@/services/payment'

const defaultState = {
    payments: [],
    payment: {},
    payment_title: null
}


const mutations = {

    [types.FETCH_PAYMENTS_REQUEST](state) {
        state.fetchingData = true
        state.error = null
        state.payment_title = null
    },
    [types.FETCH_PAYMENTS_SUCCESS](state, { payments }) {
        state.fetchingData = false
        state.error = null
        console.log(state)
        state.payments = payments
        state.payment_title = null;
    },
    [types.FETCH_PAYMENTS_FAILURE](state, { error }) {
        state.fetchingData = false
        state.error = error
        state.payment_title = null
    },
    [types.GET_PAYMENT_BY_ID_REQUEST](state) {
        state.fetchingData = true
        state.error = null
        state.payment_title = null;

    },
    [types.GET_PAYMENT_BY_ID_SUCCESS](state, { payment }) {
        state.fetchingData = false
        state.error = null
        state.payment = payment
        state.payment_title = payment.createdAt;
    },
    [types.FETCH_PAYMENTS_FAILURE](state, { error }) {
        state.fetchingData = false
        state.error = error
        state.payment_title = null;
    },
    [types.EDIT_PAYMENT_REQUEST](state) {
        state.fetchingData = true
        state.error = null

    },
    [types.EDIT_PAYMENT_SUCCESS](state, { payment }) {
        state.fetchingData = false
        state.error = null
    },
    [types.EDIT_PAYMENT_FAILURE](state, { error }) {
        state.fetchingData = false
        state.error = error
    },

}

const actions = {

    fetchPayments({ commit }, { params }) {
        commit(types.FETCH_PAYMENTS_REQUEST)
        APIpayment.getPayments(params)
            .then((response) => commit(types.FETCH_PAYMENTS_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.FETCH_PAYMENTS_FAILURE, { error: err.response.data }))
    },
    getPaymentById({ commit }, { id }) {
        commit(types.GET_PAYMENT_BY_ID_REQUEST)
        APIpayment.getPaymentById(id)
            .then((response) => commit(types.GET_PAYMENT_BY_ID_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.GET_PAYMENT_BY_ID_FAILURE, { error: err.response.data }))
    },
    editPaymentById({commit},{id,payment}){
        return new Promise((resolve, reject) => {
        commit(types.EDIT_PAYMENT_REQUEST)
        APIpayment.editPaymentById(id,payment)
            .then((response) => {
                commit(types.EDIT_PAYMENT_SUCCESS, { ...response.data })
                resolve(response)
            })
            .catch((err) => {
                commit(types.EDIT_PAYMENT_FAILURE, { error: err.response.data })
                reject(err.response.data )
            })
        })

    }

}

export default {
    state: defaultState,
    actions,
    mutations,
};

