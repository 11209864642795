export const ADD_ASSOCIATED_REQUEST = 'ADD_ASSOCIATED_REQUEST'
export const ADD_ASSOCIATED_SUCCESS = 'ADD_ASSOCIATED_SUCCESS'
export const ADD_ASSOCIATED_FAILURE = 'ADD_ASSOCIATED_FAILURE'

export const EDIT_ASSOCIATED_REQUEST = 'EDIT_ASSOCIATED_REQUEST'
export const EDIT_ASSOCIATED_SUCCESS = 'EDIT_ASSOCIATED_SUCCESS'
export const EDIT_ASSOCIATED_FAILURE = 'EDIT_ASSOCIATED_FAILURE'

export const GET_ASSOCIATED_ID_REQUEST = 'GET_ASSOCIATED_ID_REQUEST'
export const GET_ASSOCIATED_ID_SUCCESS = 'GET_ASSOCIATED_ID_SUCCESS'
export const GET_ASSOCIATED_ID_FAILURE = 'GET_ASSOCIATED_ID_FAILURE'
