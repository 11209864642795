import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    storeAssociated(data) {
        return axios.post(`${ENDPOINT_PATH}/associated`, data);
    },
    editAssociated(associated){
        return axios.put(`${ENDPOINT_PATH}/associated/${associated.id}`,associated)
    },
    getAssociatedById(id){
        return axios.get(`${ENDPOINT_PATH}/associated/${id}`)
    }
    
};
