import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    getMemberships(params = null) {
        return axios.get(`${ENDPOINT_PATH}/memberships`, { params });
    },
    getMembershipById(id){
        return axios.get(`${ENDPOINT_PATH}/membership/${id}`);
    }
};
