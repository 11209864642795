export const FETCH_PARTNERS_REQUEST = 'FETCH_PARTNERS_REQUEST'
export const FETCH_PARTNERS_SUCCESS = 'FETCH_PARTNERS_SUCCESS'
export const FETCH_PARTNERS_FAILURE = 'FETCH_PARTNERS_FAILURE'

export const GET_PARTNER_BY_ID_REQUEST = 'GET_PARTNER_BY_ID_REQUEST'
export const GET_PARTNER_BY_ID_SUCCESS = 'GET_PARTNER_BY_ID_SUCCESS'
export const GET_PARTNER_BY_ID_FAILURE = 'GET_PARTNER_BY_ID_FAILURE'

export const CHANGE_SHOW_PARTNER_ASOCIATE= 'CHANGE_SHOW_PARTNER_ASOCIATE'

export const ADD_PARTNER_REQUEST = 'ADD_PARTNER_REQUEST'
export const ADD_PARTNER_SUCCESS = 'ADD_PARTNER_SUCCESS'
export const ADD_PARTNER_FAILURE = 'ADD_PARTNER_FAILURE'

export const EDIT_PARTNER_REQUEST = 'EDIT_PARTNER_REQUEST'
export const EDIT_PARTNER_SUCCESS = 'EDIT_PARTNER_SUCCESS'
export const EDIT_PARTNER_FAILURE = 'EDIT_PARTNER_FAILURE'
