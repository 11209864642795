import * as types from '../types/user'
import APIuser from '@/services/user'


const defaultState = {
    users: [],
    user: {},
    userError: {},
    userSusccesMessage: null
}



const mutations = {

    [types.FETCH_USERS_REQUEST](state) {
        state.fetchingData = true
        state.userError = null
    },
    [types.FETCH_USERS_SUCCESS](state, { users }) {
        state.fetchingData = false
        state.userError = null
        state.users = users
    },
    [types.FETCH_USERS_FAILURE](state, { error }) {
        state.fetchingData = false
        state.userError = error
    },
    [types.ADD_USER_REQUEST](state) {
        state.fetchingData = true
        state.userError = null
        state.userSusccesMessage = null
    },
    [types.ADD_USER_SUCCESS](state, { user, message }) {
        state.fetchingData = false
        state.userError = null
        state.userSusccesMessage = message
    },
    [types.ADD_USER_FAILURE](state, { error }) {
        state.fetchingData = false
        state.userError = error
        state.userSusccesMessage = null
    },
    [types.EDIT_USER_REQUEST](state) {
        state.fetchingData = true
        state.userError = null
        state.userSusccesMessage = null
    },
    [types.EDIT_USER_SUCCESS](state, { user, message }) {

        state.fetchingData = false
        state.userError = null
        state.userSusccesMessage = message
    },
    [types.EDIT_USER_FAILURE](state, { error }) {
        state.fetchingData = false
        state.userError = error
        state.userSusccesMessage = null
    },
    [types.GET_USER_BY_ID_REQUEST](state) {
        state.fetchingData = true
        state.userError = null
    },
    [types.GET_USER_BY_ID_SUCCESS](state, { user, message }) {
        state.fetchingData = false
        state.userError = null
        state.user = user
    },
    [types.GET_USER_BY_ID_FAILURE](state, { error }) {
        state.fetchingData = false
        state.userError = error
    },

}

const actions = {
    fetchUsers({ commit }, { params }) {
        commit(types.FETCH_USERS_REQUEST)
        APIuser.getUsers(params)
            .then((response) => commit(types.FETCH_USERS_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.FETCH_USERS_FAILURE, { error: err.response.data }))
    },
    addUser({ commit }, { user }) {
        commit(types.ADD_USER_REQUEST)
        APIuser.storeUser(user)
            .then((response) => commit(types.ADD_USER_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.ADD_USER_FAILURE, { error: err.response.data }))
    },
    getUserById({ commit }, { id }) {
        commit(types.GET_USER_BY_ID_REQUEST)
        APIuser.getUserById(id)
            .then((response) => commit(types.GET_USER_BY_ID_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.GET_USER_BY_ID_FAILURE, { error: err.response.data }))
    },
    editUser({ commit }, { user }) {
        commit(types.EDIT_USER_REQUEST)
        APIuser.editUser(user)
            .then((response) => commit(types.EDIT_USER_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.EDIT_USER_FAILURE, { error: err.response.data }))
    }
}

export default {
    state: defaultState,
    // getters,
    actions,
    mutations,
};

