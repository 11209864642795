<template>
 <v-app :style="{ background: $vuetify.theme.themes['light'].background }">
        <router-view></router-view>
         </v-app>
</template>

<script>
// import Sidebar from "@/components/layout/SideBar.vue";
// import Header from "@/components/layout/Header.vue"
import { mapActions, mapGetters } from "vuex";

export default {
  name: 'App',

  // components: {
  //   Sidebar,
  //   Header
  // },
  computed: {
    // ...mapGetters([
    //   'isLoggedIn', 
    // ]),
    // isLoggedIn : function(){
    //   return this.$store.getters.isLoggedIn
    // },
    // theme(){
    //   return this.isLoggedIn() ? 'dark' : 'light'
    // }
  },
  methods :{
    ...mapActions(['logout'])

  },
  data: () => ({
    //  drawer: true

  }),
  created: function () {
  
    this.$http.interceptors.response.use(undefined, function (err) {
      return new Promise(function (resolve, reject) {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {         
          this.logout()          
        }
        throw err;
      });
    });
  },
  // chageDrawer (val) {
  //     this.drawer = val
  //   }
};
</script>
