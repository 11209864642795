import APIreception from '@/services/reception'
import * as types from '../types/reception'


const defaultState = {
    receptions: [],
}

const mutations = {

    [types.FETCH_RECEPTIONS_REQUEST](state) {
        state.fetchingData = true
        state.error = null
    },
    [types.FETCH_RECEPTIONS_SUCCESS](state, { receptions }) {
        state.fetchingData = false
        state.error = null
        state.receptions = receptions
    },
    [types.FETCH_RECEPTIONS_FAILURE](state, { error }) {
        state.fetchingData = false
        state.error = error
    },
    [types.FETCH_SAVE_RECEPTION_BY_USER_ID_REQUEST](state) {
        state.fetchingData = true;
        state.error = null
    },
    [types.FETCH_SAVE_RECEPTION_BY_USER_ID_SUCCESS](state, { reception }) {
        state.fetchingData = false;
        state.error = null
    },
    [types.FETCH_SAVE_RECEPTION_BY_USER_ID_FAILURE](state, { error }) {
        state.fetchingData = false;
        state.error = error
    },
    [types.FETCH_SAVE_RECEPTION_BY_DOCUMENT_NUMBER_REQUEST](state) {
        state.fetchingData = true;
        state.error = null
    },
    [types.FETCH_SAVE_RECEPTION_BY_DOCUMENT_NUMBER_SUCCESS](state, { reception }) {
        state.fetchingData = false;
        state.error = null
    },
    [types.FETCH_SAVE_RECEPTION_BY_DOCUMENT_NUMBER_FAILURE](state, { error }) {
        state.fetchingData = false;
        state.error = error
    }
}

const actions = {
    fetchReceptions({ commit }, { params }) {
        commit(types.FETCH_RECEPTIONS_REQUEST)
        APIreception.getReceptions(params)
            .then((response) => commit(types.FETCH_RECEPTIONS_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.FETCH_RECEPTIONS_FAILURE, { error: err.response.data }))
    },
    addReceptionByUserId({ commit }, { user_id }) {
        return new Promise((resolve, reject) => {
            commit(types.FETCH_SAVE_RECEPTION_BY_USER_ID_REQUEST);
            APIreception.addReceptionByUserId(user_id)
                .then((response) => {
                    commit(types.FETCH_SAVE_RECEPTION_BY_USER_ID_SUCCESS,{ ...response.data });
                    resolve(response)
                })
                .catch((err) => {
                    commit(types.FETCH_SAVE_RECEPTION_BY_USER_ID_FAILURE, { error: err.response.data })
                    reject(err.response.data)
                })
        })
    },
    addReceptionByDocumentNumber({commit},{document_number}){
        return new Promise((resolve, reject) => {
            commit(types.FETCH_SAVE_RECEPTION_BY_DOCUMENT_NUMBER_REQUEST);
            APIreception.addReceptionByDocumentNumber(document_number)
                .then((response) => {
                    commit(types.FETCH_SAVE_RECEPTION_BY_DOCUMENT_NUMBER_SUCCESS,{ ...response.data });
                    resolve(response)
                })
                .catch((err) => {
                    commit(types.FETCH_SAVE_RECEPTION_BY_DOCUMENT_NUMBER_FAILURE, { error: err.response.data })
                    reject(err.response.data)
                })
        })
    }
}

export default {
    state: defaultState,
    // getters,
    actions,
    mutations,
};
