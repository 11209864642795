import * as types from '../types/associated'
import APIassociated from '@/services/associated'


const defaultState = {
    associated: {},
    associatedError: {},
    associatedSuccessMessage: null

}

const mutations = {

    [types.ADD_ASSOCIATED_REQUEST](state) {
        state.fetchingData = true
        state.associatedError = null
        state.associatedSuccessMessage = null
    },
    [types.ADD_ASSOCIATED_SUCCESS](state, { partner, message }) {
        state.fetchingData = false
        state.associatedError = null
        state.associatedSuccessMessage = message
    },
    [types.ADD_ASSOCIATED_FAILURE](state, { error }) {
        state.fetchingData = false
        state.associatedError = error
        state.associatedSuccessMessage = null
    },
    [types.EDIT_ASSOCIATED_REQUEST](state) {
        state.fetchingData = true
        state.associatedError = null
        state.associatedSuccessMessage = null
    },
    [types.EDIT_ASSOCIATED_SUCCESS](state, { partner, message }) {
        state.fetchingData = false
        state.associatedError = null
        state.associatedSuccessMessage = message
        //edit partner commit
    },
    [types.EDIT_ASSOCIATED_FAILURE](state, { error }) {
        state.fetchingData = false
        state.associatedError = error
        state.associatedSuccessMessage = null
    },
    [types.GET_ASSOCIATED_ID_REQUEST](state) {
        state.fetchingData = true
        state.associatedError = null
    },
    [types.GET_ASSOCIATED_ID_SUCCESS](state, { associated, message }) {
        state.fetchingData = false
        state.associatedError = null
        state.associated = associated
        //edit partner commit
    },
    [types.GET_ASSOCIATED_ID_FAILURE](state, { error }) {
        state.fetchingData = false
        state.associatedError = error
    },

}



const actions = {

    addAssociated({ commit }, { associated }) {
        commit(types.ADD_ASSOCIATED_REQUEST)
        APIassociated.storeAssociated(associated)
            .then((response) => commit(types.ADD_ASSOCIATED_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.ADD_ASSOCIATED_FAILURE, { error: err.response.data }))
    },
    editAssociated({ commit }, { associated }) {
        commit(types.EDIT_ASSOCIATED_REQUEST)
        APIassociated.editAssociated(associated)
            .then((response) => commit(types.EDIT_ASSOCIATED_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.EDIT_ASSOCIATED_FAILURE, { error: err.response.data }))
    },
    getAssociatedById({ commit }, { id }) {
        commit(types.GET_ASSOCIATED_ID_REQUEST)
        APIassociated.getAssociatedById(id)
            .then((response) => commit(types.GET_ASSOCIATED_ID_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.GET_ASSOCIATED_ID_FAILURE, { error: err.response.data }))
    },

}

export default {
    state: defaultState,
    // getters,
    actions,
    mutations,
};
