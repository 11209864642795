import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import DefaultRoute from '@/helpers/default-routes.js';
import router from './router'
import store from './store'
import Axios from 'axios'
import vuetify from './plugins/vuetify';
import '@babel/polyfill'
import * as types from './store/types/auth'
import '@/assets/css/main.css';
import mixins from "@/mixins/index";

Vue.config.productionTip = false
Vue.prototype.$http = Axios;
const token = localStorage.getItem('token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}
Vue.use(DefaultRoute);
Vue.use(Vuex)
Vue.mixin({
  methods: mixins
})
new Vue({
  router,
  store,
  beforeCreate() { this.$store.commit(types.INITIALIZE_STORE);},
  vuetify,
  render: h => h(App)
}).$mount('#app')
