export const FETCH_PAYMENTS_REQUEST = 'FETCH_PAYMENTS_REQUEST'
export const FETCH_PAYMENTS_SUCCESS = 'FETCH_PAYMENTS_SUCCESS'
export const FETCH_PAYMENTS_FAILURE = 'FETCH_PAYMENTS_FAILURE'

export const GET_PAYMENT_BY_ID_REQUEST = 'GET_PAYMENT_BY_ID_REQUEST'
export const GET_PAYMENT_BY_ID_SUCCESS = 'GET_PAYMENT_BY_ID_SUCCESS'
export const GET_PAYMENT_BY_ID_FAILURE = 'GET_PAYMENT_BY_ID_FAILURE'

export const EDIT_PAYMENT_REQUEST = 'EDIT_PAYMENT_REQUEST'
export const EDIT_PAYMENT_SUCCESS = 'EDIT_PAYMENT_SUCCESS'
export const EDIT_PAYMENT_FAILURE = 'EDIT_PAYMENT_FAILURE'

