export const FETCH_RECEPTIONS_REQUEST = 'FETCH_RECEPTIONS_REQUEST'
export const FETCH_RECEPTIONS_SUCCESS = 'FETCH_RECEPTIONS_SUCCESS'
export const FETCH_RECEPTIONS_FAILURE = 'FETCH_RECEPTIONS_FAILURE'

export const FETCH_SAVE_RECEPTION_BY_USER_ID_REQUEST = 'FETCH_SAVE_RECEPTION_BY_USER_ID_REQUEST'
export const FETCH_SAVE_RECEPTION_BY_USER_ID_SUCCESS = 'FETCH_SAVE_RECEPTION_BY_USER_ID_SUCCESS'
export const FETCH_SAVE_RECEPTION_BY_USER_ID_FAILURE = 'FETCH_SAVE_RECEPTION_BY_USER_ID_FAILURE'

export const FETCH_SAVE_RECEPTION_BY_DOCUMENT_NUMBER_REQUEST = 'FETCH_SAVE_RECEPTION_BY_DOCUMENT_NUMBER_REQUEST'
export const FETCH_SAVE_RECEPTION_BY_DOCUMENT_NUMBER_SUCCESS = 'FETCH_SAVE_RECEPTION_BY_DOCUMENT_NUMBER_SUCCESS'
export const FETCH_SAVE_RECEPTION_BY_DOCUMENT_NUMBER_FAILURE = 'FETCH_SAVE_RECEPTION_BY_DOCUMENT_NUMBER_FAILURE'