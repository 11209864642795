export const INITIALIZE_STORE = 'INITIALIZE_STORE'

export const AUTH_REQUEST = 'AUTH_REQUEST'
export const AUTH_REQUEST_SUCCESS = 'AUTH_REQUEST_SUCCESS'
export const AUTH_REQUEST_FAILURE = 'AUTH_REQUEST_FAILURE'

export const LOGOUT = 'LOGOUT'

export const SET_ITEM_TOKEN = 'SET_ITEM_TOKEN'

export const SET_DEFAULT_HEADER = 'SET_DEFAULT_HEADER'

export const REMOVE_TOKEN = 'REMOVE_TOKEN'