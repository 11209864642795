import * as types from '../types/partner'
import APIpartner from '@/services/partner'



const defaultState = {
    partners: [],
    partner: {},
    membership_id: null,
    partnerError: {},
    partnerSusccesMessage: null
}

const mutations = {

    [types.FETCH_PARTNERS_REQUEST](state) {
        state.fetchingData = true
        state.partnerError = null
    },
    [types.FETCH_PARTNERS_SUCCESS](state, { partners }) {
        state.fetchingData = false
        state.partnerError = null
        state.partners = partners
    },
    [types.FETCH_PARTNERS_FAILURE](state, { error }) {
        state.fetchingData = false
        state.partnerError = error
    },
    [types.ADD_PARTNER_REQUEST](state) {
        state.fetchingData = true
        state.partnerError = null
        state.partnerSusccesMessage = null
    },
    [types.ADD_PARTNER_SUCCESS](state, { partner, message }) {
        state.fetchingData = false
        state.partnerError = null
        state.partnerSusccesMessage = message
    },
    [types.ADD_PARTNER_FAILURE](state, { error }) {
        state.fetchingData = false
        state.partnerError = error
        state.partnerSusccesMessage = null
    },
    [types.EDIT_PARTNER_REQUEST](state) {
        state.fetchingData = true
        state.partnerError = null
        state.partnerSusccesMessage = null
    },
    [types.EDIT_PARTNER_SUCCESS](state, { partner, message }) {

        state.fetchingData = false
        state.partnerError = null
        state.partnerSusccesMessage = message
        //edit partner commit
    },
    [types.EDIT_PARTNER_FAILURE](state, { error }) {
        state.fetchingData = false
        state.partnerError = error
        state.partnerSusccesMessage = null
    },
    [types.GET_PARTNER_BY_ID_REQUEST](state) {
        state.fetchingData = true
        state.partnerError = null
    },
    [types.GET_PARTNER_BY_ID_SUCCESS](state, { partner, message }) {
        state.fetchingData = false
        state.partnerError = null
        state.partner = partner
        state.membership_id = partner.membership.id;
        //edit partner commit
    },
    [types.GET_PARTNER_BY_ID_FAILURE](state, { error }) {
        state.fetchingData = false
        state.partnerError = error
    },
    [types.CHANGE_SHOW_PARTNER_ASOCIATE](state, { id }) {
        state.partner.associates.map(e => {
            if (e.id == id) {
                e.show = !e.show
            }
            return e;
        })
    }

}


const actions = {
    fetchPartners({ commit }, { params }) {
        commit(types.FETCH_PARTNERS_REQUEST)
        APIpartner.getPartners(params)
            .then((response) => commit(types.FETCH_PARTNERS_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.FETCH_PARTNERS_FAILURE, { error: err.response.data }))
    },
    addPartner({ commit }, { partner }) {
        commit(types.ADD_PARTNER_REQUEST)
        APIpartner.storePartner(partner)
            .then((response) => commit(types.ADD_PARTNER_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.ADD_PARTNER_FAILURE, { error: err.response.data }))
    },
    getPartnerById({ commit }, { id }) {
        commit(types.GET_PARTNER_BY_ID_REQUEST)
        APIpartner.getPartnerById(id)
            .then((response) => commit(types.GET_PARTNER_BY_ID_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.GET_PARTNER_BY_ID_FAILURE, { error: err.response.data }))
    },
    showPartnerAssociated({ commit }, { id }) {
        commit(types.CHANGE_SHOW_PARTNER_ASOCIATE, { id })
    },
    editPartner({ commit }, { partner }) {
        commit(types.EDIT_PARTNER_REQUEST)
        APIpartner.editPartner(partner)
            .then((response) => commit(types.EDIT_PARTNER_SUCCESS, { ...response.data }))
            .catch((err) => commit(types.EDIT_PARTNER_FAILURE, { error: err.response.data }))
    }
}

const getters = {
    getParnerts: (state) => () => {
        return state.partners;
    },
    getPartner: (state) => () => {
        return state.partner;
    }
}



export default {
    state: defaultState,
    getters,
    actions,
    mutations,
};


