import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    getDocuments() {
        return axios.get(`${ENDPOINT_PATH}/documents`);
    },
    getLocations() {
        return axios.get(`${ENDPOINT_PATH}/locations`);
    },
    getRelationships() {
        return axios.get(`${ENDPOINT_PATH}/relationships`);
    },
    uploadImage(data){
        return axios.post(`${ENDPOINT_PATH}/upload/images`, data);
    },
    uploadFile(data){
        return axios.post(`${ENDPOINT_PATH}/upload/files`, data);
    },
    getPaymentMethods(){
        return axios.get(`${ENDPOINT_PATH}/payment-methods`);
    },
    getDepartments(){
        return axios.get(`${ENDPOINT_PATH}/departments`);
    },
    getProvinces(department_id) {
        return axios.get(`${ENDPOINT_PATH}/provinces/${department_id}`);
    },
    getDistricts(province_id) {
        return axios.get(`${ENDPOINT_PATH}/districts/${province_id}`);
    },
    getParnerTypes(){
        return axios.get(`${ENDPOINT_PATH}/partner-types`);
    }
};
