import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {

    getPayments(params = null) {
        return axios.get(`${ENDPOINT_PATH}/payments`, { params });
    },
    getPaymentById(id){
        return axios.get(`${ENDPOINT_PATH}/payment/${id}`);
    },
    editPaymentById(id,payment){
        return axios.put(`${ENDPOINT_PATH}/payment-edit/${id}`,payment);
    }

}
